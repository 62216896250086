<template>
  <div class="content-area shuttle-plan-list">
    <hero-bar>
      {{ $t('shuttlePlans') }}

      <b-tooltip slot="right" :label="$t('refresh')" type="is-dark" position="is-bottom">
        <b-button
          id="refresh-button"
          class="mr-10"
          size="is-circle"
          type="is-link"
          outlined
          :class="{ 'is-loading': refreshLoading }"
          icon-left="refresh"
          @click="refresh"
        />
      </b-tooltip>

      <b-button
        v-if="checkPermission('shuttle_create')"
        id="new-plan"
        slot="right"
        tag="router-link"
        :to="{ name: 'shuttles.create'}"
        icon-left="plus-circle"
        type="is-link"
      >
        Yeni Servis Planı
      </b-button>
    </hero-bar>

    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced bg-none">
        <smart-table
          ref="table"
          data-url="shuttle-list"
          default-sort="id"
          :loading.sync="refreshLoading"
        >
          <template slot="action-column" slot-scope="{ row }">
            <b-tooltip :label="$t('delete')" type="is-dark" position="is-bottom">
              <b-button
                outlined
                size="is-small"
                class="button-green is-danger mr-5 only-icon"
                icon-left="close-circle"
                :class="{ 'is-loading': hasLoading(row.id) }"
                @click="deletePlan(row.id)"
              />
            </b-tooltip>
            <b-button
              outlined
              size="is-small"
              class="button-purble btn-details"
              tag="router-link"
              :to="{ name: 'shuttles.show', params: { id: row.id }}"
              icon-left="arrow-right-bold"
            >
              {{ $t('edit') }}
            </b-button>
          </template>
        </smart-table>
      </card-component>
    </section>
  </div>
</template>

<script>
export default {
  title: ({ $i18n }) => $i18n.t('shuttlePlans'),
  components: {
    HeroBar: () => import('@/components/HeroBar'),
    CardComponent: () => import('@/components/CardComponent'),
    SmartTable: () => import('@/components/SmartTable'),
  },
  data () {
    return {
      refreshLoading: false,
      deleteLoading: null,
    }
  },
  methods: {
    refresh () {
      this.$refs.table.loadData()
    },
    hasLoading (id) {
      return this.deleteLoading == id
    },
    async deletePlan (id) {
      this.deleteLoading = id
      let shuttleRegisterCount = 0

      try {
        const shuttleRegisterRequest = await this.$http.get(`shuttle-count/${id}`)
        shuttleRegisterCount = shuttleRegisterRequest.data.register_count
      } catch {
        shuttleRegisterCount = 0
      }

      let message = ''

      if (shuttleRegisterCount > 0) {
        message = `Bu güzergaha ait ${shuttleRegisterCount} başvuru var, silerseniz o başvurular da silinecektir.<br>`
      }

      this.$buefy.dialog.confirm({
          title: 'Servis Planı Sil',
          message: `${message}Servis Planını <b>silmek</b> istediğinizden emin misiniz?`,
          cancelText: 'İptal',
          confirmText: 'Planı Sil',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.$http
              .delete(`shuttles/${id}`)
              .then(() => {
                this.$buefy.toast.open({ message: 'Servis Planı Silindi.', type: 'is-success' })
                this.refresh();
                this.deleteLoading = null
              })
          },
          onCancel: () => {
            this.deleteLoading = null
          }
      })
    }
  }
}
</script>
